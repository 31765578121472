import { Box, FormControl, IconButton, InputBase, InputLabel, Menu, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import React from 'react'
import './SearchBar.css'
import { grey } from '@mui/material/colors';
const ImgHeader = `url(/img/HEADER.png)`;

const SearchBar = (props) => {
    
      
  return (
    <Box className='body-search-bar' container sx={{backgroundImage: ImgHeader}}>
      <Box className='search-bar-input' sx={{width:{xs: "90%", sm: "90%", md: "80%", lg: "70%", xl: "50%"}, }}>
            <Typography variant='h5'  padding={2}>{props.title}</Typography>
            <Box fullWidth justifyContent="center" alignItems="center"   >
                <Paper elevation={3} sx={{ width: '100%', borderRadius: '12px', p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid #2196f3' } }}>
                    <InputBase
                        sx={{ ml: 1, flex: 1,  }}
                        placeholder="Pesquisar"
                        value={props.filter}
                        onChange={(e) => props.setFilter(e.target.value)}
                        onKeyDown={(e) => {if(e.key === "Enter")props.handleSearch()}}
                    />   
                    {props?.FilterButton}                                  
                    <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={props.handleSearch}>
                        <SearchIcon />
                    </IconButton>
                </Paper >
            </Box>
    </Box>
  </Box>  
  )
}

export default SearchBar